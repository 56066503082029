import * as React from "react"
import { AssetHintArrowDown } from "../../components/assets"
import ProjectStudyFeature from "../project/projectStudyFeature"

const LighthouseWebComponents = () => {
  return (
  <ProjectStudyFeature
    id="webComponents"
    title="Web Components"
    subtitle={<>
    In addition to mobile components, I have designed, prototyped, and implemented 30+ desktop components in React & SASS. I have catered for mouse & keyboard input on Desktop/Laptop, and taken into consideration web and desktop-specific information density and conventions such as radio buttons, checkboxes, and forms.
    <br /> <br />
    I have taken the initiative to design and implemented a React Web App which showcased the visual language as well as some of the web components.
    </>}
    content={<>
      <div className="hintBannerContainer">
        <div className="hintBanner">
          <p className="hintText">Play with me!</p>
          <AssetHintArrowDown className="hintIcon" />
        </div>
      </div>

      <iframe src="https://sprightly-belekoy-609108.netlify.app" title="Lightricks Web Design System" style={{minHeight: '640px'}}></iframe>
    </>}
  />
  )
}

export default LighthouseWebComponents
