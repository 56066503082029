import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectStudyFeature from "../project/projectStudyFeature"
import DialogSheetVideo from "../../assets/lighthouseDialogSheet.mp4"
import StoryVideo from "../../assets/lighthouseStory.mp4"


const ComponentsGrid = () => {
  return (
    <div className="componentsGrid">
      <div className="gridRow">
        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsActivityIndicatorDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="An activity indicator component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsActivityIndicator.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="An activity indicator component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsCheckboxDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A checkbox component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsCheckbox.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A checkbox component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsCloseButtonDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A close button component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsCloseButton.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A close button component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsFloatingButtonDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A floating button component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsFloatingButton.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A floating button component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsSwitchDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A switch component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsSwitch.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A switch component."
            class="lightTheme"
          />
        </div>
      </div>

      <div className="gridRow second">
        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsPageControlDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A page control component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsPageControl.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A page control component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsButtonsDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="Buttons component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsButtons.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="Buttons component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsSliders1Dark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A slider component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsSliders1.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A slider component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsSliders2Dark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A slider component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsSliders2.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A slider component."
            class="lightTheme"
          />
        </div>
      </div>

      <div className="gridRow third">
        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsBannerDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A banner component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsBanner.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A banner component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsTooltipDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A tooltip component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsTooltip.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A tooltip component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsSegmentsDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="Segments component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsSegments.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="Segments component."
            class="lightTheme"
          />
        </div>
      </div>

      <div className="gridRow fourth">
        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsNotifierLoadingDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A notifier component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsNotifierLoading.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A notifier component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsNotifierSuccessDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A notifier component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsNotifierSuccess.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A notifier component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsNotifierFailDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A notifier component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsNotifierFail.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A notifier component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsSearchDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A search component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsSearch.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A search component."
            class="lightTheme"
          />
        </div>
      </div>


      <div className="gridRow fifth">
        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsAlertDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="An alert component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsAlert.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="An alert component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsActionSheetDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="An action sheet component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsActionSheet.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="An action sheet component."
            class="lightTheme"
          />
        </div>
      </div>

      <div className="gridRow sixth">
        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsPopupMenuDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A pop up menu component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsPopupMenu.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A pop up menu component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsDropdownMenuDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A dropdown menu component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsDropdownMenu.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A dropdown menu component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem">
          <StaticImage
            src="../../images/lighthouse/componentsPresetsDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A presets component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsPresets.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A presets component."
            class="lightTheme"
          />
        </div>
      </div>

      <div className="gridRow seventh">
        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsFormDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A form component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsForm.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A form component."
            class="lightTheme"
          />
        </div>

        <div className="gridItem square">
          <StaticImage
            src="../../images/lighthouse/componentsListDark.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A list component."
            class="darkTheme"
          />

          <StaticImage
            src="../../images/lighthouse/componentsList.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A list component."
            class="lightTheme"
          />
        </div>
      </div>

      <div className="gridRow eith">
        <div className="gridItem">
          <video controls autoplay>
            <source src={DialogSheetVideo} type="video/mp4" />
          </video>
        </div>

        <div className="gridItem">
          <video controls autoplay>
            <source src={StoryVideo} type="video/mp4" />
          </video>
        </div>
      </div>

    </div>
  )
}

const LighthouseMobileComponents = () => {
  return (
    <ProjectStudyFeature
      id="mobileComponents"
      title="Mobile Components"
      subtitle="I have designed, prototyped, and defined  26+ platform-agnostic mobile components. Each component was built with the Lightricks design language in mind, bringing consistency and predictability across all mobile apps."
      content={<ComponentsGrid />}
    />
  )
}

export default LighthouseMobileComponents
