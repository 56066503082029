import * as React from "react"
import ProjectStudyEpic from "../project/projectStudyEpic"
import ProjectStudyStory from "../project/projectStudyStory"
import { StaticImage } from "gatsby-plugin-image"

const LighthouseApproach = () => {
  return (
  <ProjectStudyEpic
    title="Approach"
    subtitle=""
    content={
    <div className="epicColumn">
    <div className="epicRow">
      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          title=""
          subtitle="As a Senior Product Designer on a team of 2, I was given the responsibility to explore existing painpoints of both designers and engineers, gather common patterns across all Lightricks apps, help establish basic foundation of the design system - focusing on creating a dynamic theming system consistent across all apps while being flexible to give enough of freedom to all; propose new workflows, design, prototype and test all components, create documentation and design resources, while working with the tech infrastructure team on design system implementation, mentor and teach others about best design practices, platform-specific conventions, Figma how-to's, and last but not least keep everyone updated on any changes, and additions to the design system while gathering and integrating feedback from all teams - supporting 20+ designers, 100+ developers, and 9+ apps across 3 platforms."
          images="noImages"
        />
      </div>

      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition="cover"
          cardStyle="primaryCell"
          title=""
          subtitle=""
          images="imageOnly"
          image1={<StaticImage
            src="../../images/lighthouse/approach.jpg"
            placeholder="blurred"
            layout="constrained"
            quality={95}
            alt="A man standing in front of components."/>}
        />
      </div>
    </div>

    <ProjectStudyStory
    previewPosition=""
    cardStyle="primaryCell"
    title="The Process"
    subtitle={<>
    My process would start with research where I would talk to product teams to understand their needs and painpoints, as well as audit existing components. After getting enough of context, I would design components in Figma, and prototype them in Origami Studio - thinking through interaction design and adding little details with transitions and haptic feedback. The resulting design and prototypes would be tested to make sure they solve specific needs and pinpoints and would work in all existing apps. 
    <br /><br />
    In addition, I would present the results to teams and gather feedback to make further improvements. Once everyone involved was happy, I would go to organize and clean up designs in Figma, thoroughly document the component's purpose, behavior, properties, and usage guidelines, as well as create a thorough annotated blueprint for engineers. 
    <br /><br />
    When a component was prioritized by the tech infrastructure team to be developed, we would have a kick off meeting to present the component and its behavior, discussing all the details and edge cases to make sure we are on the same page. The naming convention of components and their properties would be shared between both engineers and developers so it was critical to come to consensus while considering technical capabilities and constraints as well as the capabilities and constraints of the Figma design tool. 
    <br /><br />
    If there were any issues or concerns, I would go back to the drawing board to further refine each component to make sure we deliver the best experience to all. When a component was built up to everyone's high standards, approved and published, I would then update everyone. If there were requests for improvements, changes and additions to existing components, they were prioritized and implemented while keeping everyone updated. 
    <br /><br />
    I would also support individual designers - not only when they were converting their existing app designs to the Lightricks Design System - but also providing them with advice on the best design practices, discussing the intended use of specific styles and components, and gather any issues and ideas for improvement. The Lightricks Design System was made to support the product teams, not to be authoritarian and constraining, so the end product design was up to each team and not the Design System Team.
    </>}
    images="noImages"
  />
    </div>}
  />
  )
}

export default LighthouseApproach
