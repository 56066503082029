import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectAttribute from "../project/projectAttribute"
import ProjectDetail from "../project/projectDetail"

const LighthouseDetails = () => {
  return (
  <ProjectDetail
    projectIcon={
      <StaticImage
        src="../../images/imageProjectPreviewIconLightricks.png"
        placeholder="blurred"
        layout="constrained"
        quality={100}
        alt="A Lightricks logo."
      />
    }
    projectName="Lightricks Design System"
    projectOwner="Lightricks, Inc."
    projectButtonLink="https://lightricks.com/"
    projectButtonTitle="Learn About Lightricks"
    projectButtonType=""
    projectDescription="Lightricks Design System team was formed as an effort to establish a coherent and consistent source of components, styles, and patterns to make it seamless and more efficient for Lightricks' Product Designers to design consistent experiences across all Lightricks apps, and bridge the gap between designers and developers with a common shared language."
    projectSolutions={<div>
      <ProjectAttribute
        type="linked"
        link="#mobileComponents"
        title="Mobile Components"
        detail="Designed, prototyped, and defined 26+ platform-agnostic mobile components."
      />

      <ProjectAttribute
        type="linked"
        link="#webComponents"
        title="Web Components"
        detail="Designed, prototyped, and implemented 30+ desktop components in React & SASS."
      />

      <ProjectAttribute
        type="linked"
        link="#designLanguage"
        title="Design Language"
        detail="Developed scalable, flexible, and themable color framework."
      />

      <ProjectAttribute
        type="linked"
        link="#componentDocumentation"
        title="Component Documentation"
        detail="Created a documentation structure and documented all components."
      />

      <ProjectAttribute
        type="linked"
        link="#mobileApp"
        title="Mobile & Web App"
        detail="Designed a mobile catalog app with a list of styles and tweakable components."
      />
    </div>}
    projectRole="Senior Product Designer"
    projectDuration="2021-2022"
    projectSummary="Helped establish basic foundation of the design system. Proposed new workflows. Designed, prototyped and tested components - both on mobile and desktop. Created documentation and design resources. Worked closely with the tech infrastructure team on design system implementation. Mentored and taught others about best design practices, platform-specific conventions, Figma how-to's. Presented changes, and additions to the design system while gathering and integrating feedback from all teams - supporting 20+ designers, 100+ developers, and 9+ apps across 3 platforms. Worked closely with the Facetune team to redesign the Facetune app (used by 200M+ people)."
    projectAttributes={<div>
        <ProjectAttribute
          title="Design"
          detail="End-to-End Product Design, UI Design, Interaction Design, High-Fidelity Prototyping, Design System Design"
        />

        <ProjectAttribute
          title="Misc"
          detail="Documentation, UI Development"
        />


        <ProjectAttribute
          title="Tools"
          detail="Figma, Origami Studio, Xcode"
        />

        <ProjectAttribute
          title="Platforms"
          detail="iOS, Android, Web"
        />
    </div>}
  />
  )
}

export default LighthouseDetails
