import * as React from "react"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"
import { StaticImage } from "gatsby-plugin-image"

const LighthouseComponentDocumentation = () => {
  return (
  <ProjectStudyFeature
    id="componentDocumentation"
    title="Component Documentation"
    subtitle="Design System is not just aesthetic artifacts. Documentation turns the Design System into something people can understand and follow and is crucial for adoption. My goal was to provide context and usage guidelines, so I have created the following structure written documentation for every single component."
    content={<>
    <ProjectStudyStory
      previewPosition="previewOnLeft"
      cardStyle="secondaryCell"
      textStyle=""
      title="Component Status"
      subtitle="This section provides general details and references. It includes the name of the component creator and a point of contact, timestamp of when the component was last updated, link to a design guide created by the component designer and used by the development team to implement the component in code, content guide created by the content strategy team defining guidelines and examples of copywriting for that specific component and localization, link to the implemented component on GitHub, design status (not designed, in progress, pending approval, and available), iOS implementation status, Android implementation status, documentation status, content guide status, and design guide status."
      images="singleImage"
      imageStyle=""
      image1={<StaticImage
        src="../../images/lighthouse/documentationStatus.png"
        placeholder="blurred"
        layout="constrained"
        quality={100}
        alt="A documentation mockup."/>}
    />

    <ProjectStudyStory
      previewPosition="previewOnLeft"
      cardStyle="secondaryCell"
      textStyle=""
      title="Summary"
      subtitle="This is a general description of the component without going into too much detail."
      images="singleImage"
      imageStyle=""
      image1={<StaticImage
        src="../../images/lighthouse/documentationSummary.png"
        placeholder="blurred"
        layout="constrained"
        quality={100}
        alt="A documentation mockup."/>}
    />

    <ProjectStudyStory
      previewPosition="previewOnLeft"
      cardStyle="secondaryCell"
      textStyle=""
      title="Behavior"
      subtitle="This section describes how the component works and adapts."
      images="singleImage"
      imageStyle=""
      image1={<StaticImage
        src="../../images/lighthouse/documentationBehavior.png"
        placeholder="blurred"
        layout="constrained"
        quality={100}
        alt="A documentation mockup."/>}
    />

    <ProjectStudyStory
      previewPosition="previewOnLeft"
      cardStyle="secondaryCell"
      textStyle=""
      title="Usage"
      subtitle="Guidelines on how the component is intended to be used."
      images="singleImage"
      imageStyle=""
      image1={<StaticImage
        src="../../images/lighthouse/documentationUsage.png"
        placeholder="blurred"
        layout="constrained"
        quality={100}
        alt="A documentation mockup."/>}
    />

    <ProjectStudyStory
      previewPosition="previewOnLeft"
      cardStyle="secondaryCell"
      textStyle=""
      title="Properties"
      subtitle="This section lists all the properties of the components that can be adjusted in both Figma and the codebase including any of the components' texts and assets."
      images="singleImage"
      imageStyle=""
      image1={<StaticImage
        src="../../images/lighthouse/documentationProperties.png"
        placeholder="blurred"
        layout="constrained"
        quality={100}
        alt="A documentation mockup."/>}
    />

    <ProjectStudyStory
      previewPosition=""
      cardStyle="secondaryCell"
      textStyle=""
      title="Usage Examples"
      subtitle="To better illustrate how the component can be used, I have created examples section with real-world use of each component in one of the Lightricks apps."
      images="singleImage"
      imageStyle=""
      image1={<StaticImage
        src="../../images/lighthouse/documentationExamples.png"
        placeholder="blurred"
        layout="constrained"
        quality={100}
        alt="A documentation mockup."/>}
    />
    </>}
  />
  )
}

export default LighthouseComponentDocumentation
