import * as React from "react"

const LighthouseSummary = () => {
  return (
  <section className="mercurySection summarySection studySummary">
    <div className="sectionContent">
      <div className="studySummaryText">
        <h2 className="studySummaryTitle">Design System</h2>
        <p className="studySummarySubtitle">Empowering 20+ designers and 100+ developers to be more consistent, efficient, and ship better experience across 9+ apps  and 3 platforms by creating a cross-organizational design system.</p>
      </div>
    </div>
  </section>
  )
}

export default LighthouseSummary
