import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectStudyEpic from "../project/projectStudyEpic"
import LighthouseDesignLanguage from "./lighthouseDesignLanguage"
import LighthouseMobileComponents from "./lighthouseMobileComponents"
import LighthouseWebComponents from "./lighthouseWebComponents"
import LighthouseiOSComponents from "./lighthouseiOSComponents"
import LighthouseComponentDocumentation from "./lighthouseComponentDocumentation"
import LighthouseInstructions from "./lighthouseInstructions"
import LighthouseMobileApp from "./lighthouseMobileApp"
import LighthouseMobileProjectTemplate from "./lighthouseMobileProjectTemplate"
import LighthouseResults from "./lighthouseResults"
import ProjectRecommendation from "../project/projectRecommendation"

const LighthouseOutcome = () => {
  return (
    <ProjectStudyEpic
      title="Outcome"
      subtitle=""
      content={<>
        <LighthouseMobileComponents />
        <LighthouseWebComponents />
        <LighthouseDesignLanguage />
        <LighthouseiOSComponents />
        <LighthouseComponentDocumentation />
        <LighthouseInstructions />
        <LighthouseMobileApp />
        <LighthouseMobileProjectTemplate />
        <LighthouseResults />

        <ProjectRecommendation
          image={<StaticImage
            src="../../images/recommendations/JudahGuttmann.jpg"
            placeholder="blurred"
            layout="constrained"
            quality={95}
            alt="Judah Guttmann"/>}
          name="Judah Guttmann"
          title="Former Product Manager at Facetune"
          quote={<>
            I had the pleasure of working with Phil during his time at Lightricks. During our time working together Phil proved to be extremely detail oriented, while also demonstrating a deep knowledge and understanding of both the technical requirement and limitations, as well as user needs.
            <br/><br/>
            During his time at Lightricks, Phil undertook building out our design system collaborating both with our infrastructure team to build out our components library, to work both visually and functionally across Lightricks numerous iOS product. While collecting requirements and feedback from all stakeholders and products to meet each teams specific needs and requests. Our products have never looked so polished and we owe it to his incredible efforts and attention to detail.
          </>}
        />

        <ProjectRecommendation
          image={<StaticImage
            src="../../images/recommendations/TalMoskovich.jpg"
            placeholder="blurred"
            layout="constrained"
            quality={95}
            alt="Tal Moskovich"/>}
          name="Tal Moskovich"
          title="Former Product Design Lead at Lightricks"
          quote="Phil is one of the most professional designers I’ve had the pleasure of working with. Always a deep thinker, extreme edge case detector and prototyping master. He’s helped tremendously in taking product design at Lightricks to the next level and I hope our paths cross again soon!"
        />

      <ProjectRecommendation
        image={<StaticImage
          src="../../images/recommendations/LaureScemama.jpg"
          placeholder="blurred"
          layout="constrained"
          quality={95}
          alt="Laure Scemama"/>}
        name="Laure Scemama"
        title="Product Design Team Lead at Lightricks"
        quote="Phil is a very talented designer and it was a pleasure working with him and managing him. He is very dedicated to keeping a high quality of work, both from a UX and a UI perspective. Phil is also very organized and knowledgable, always up to date and up to exploring new things. Last but not least, Phil did an amazing job working on Lightricks' Design System! So, go ahead and hire him :)"
      />

      <ProjectRecommendation
        image={<StaticImage
          src="../../images/recommendations/MichaelKimi.jpg"
          placeholder="blurred"
          layout="constrained"
          quality={95}
          alt="Michael Kimi"/>}
        name="Michael Kimi"
        title="Software Developer at Lightricks"
        quote="I’ve worked with Phil on implementing an in-house design system. Besides creation of detailed and well thought design guides, Phils have great technical abilities. He always created interactive mocks of the design guides and when necessary dived into the code and tweaked it. And as a bonus Phils is a great guy to have a conversation with."
      />

      <ProjectRecommendation
        image={<StaticImage
          src="../../images/recommendations/NetaiBenaim.jpg"
          placeholder="blurred"
          layout="constrained"
          quality={95}
          alt="Netai Benaim"/>}
        name="Netai Benaim"
        title="iOS Developer at Lightricks"
        quote="Worked with Phil on an internal Design System that is used across multiple apps. Phil is a professional designer who is comfortable around code and is easy to work with. As an engineer I often find that working with Designers invokes challenges that arise from the different languages we speak, but with Phil it was a breeze, he knows to speak developer, he makes design guides in ways that are easy to implement in code and he provides mocks and prototypes for everything!"
      />
      </>}
    />
  )
}

export default LighthouseOutcome
