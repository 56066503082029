import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"

const LighthouseiOSComponents = () => {
  return (
  <ProjectStudyFeature
    id="iOSComponents"
    title="iOS System Components"
    subtitle="To make it easier for Product Designers to optimize their designs for specific devices, I have recreated many native iOS system components - making sure that each component had accurate variants based on the all the devices used by our customers."
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="plainCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/lighthouse/iOSComponents.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="An iOS components mockup."/>}
      />
    </>}
  />
  )
}

export default LighthouseiOSComponents
