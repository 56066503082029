import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"

const LighthouseMobileProjectTemplate = () => {
  return (
  <ProjectStudyFeature
    id="mobileProjectTemplate"
    title="Mobile Project Template"
    subtitle={<>
      To make design process more organized, efficient, and consistent across the organization, I have created a Figma mobile project template for Product Designers.
      <br /> <br />
      Each file was structured to follow a part of the company's design process and included project details page with a design brief and more context around the project, final designs page, design guide page, prototyping section, ux writing section for ux writing team, design playground section for exploratory designs with iOS and Android design templates that I have created to make it easier for designers to get started, research section for ux research, new components page, and archive section.
    </>}
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/lighthouse/mobileProjectTemplate.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A mobile template screenshot."/>}
      />
    </>}
  />
  )
}

export default LighthouseMobileProjectTemplate
