import * as React from "react"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"
import { ProjectStudyStoryPoint } from "../project/projectStudyStory"

const LighthouseResults = () => {
  return (
    <ProjectStudyFeature
      id="results"
      title="Results"
      subtitle=""
      content={
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        title=""
        subtitle={<>
          <span>The Design System team has assisted multiple product teams with migration to our Design System, the latest being Facetune.</span>
          <ProjectStudyStoryPoint icon="check" text="Product teams are seeing improvements in design and development efficiency, and shipping faster." />
          <ProjectStudyStoryPoint icon="check" text="Common language between Product Designers and Developers is leading to better communication and results." />
          <ProjectStudyStoryPoint icon="check" text="Consistent UI and interaction design translates into increase in subscription rates and retention." />
          <ProjectStudyStoryPoint icon="check" text="Every product that migrated has cut hundreds of hardcoded colors down to ~20 design system colors." />
          <ProjectStudyStoryPoint icon="check" text="Products which migrated to Design System have provide more consistent and predictable experience." />
          <ProjectStudyStoryPoint icon="check" text="Improvements in cross-product efforts - the Design System is used by cross-product teams to design new features such as Feed and Onboarding." />
          <ProjectStudyStoryPoint icon="check" text="We're seeing great adoption rates of the design system styles and components across all major product teams." />
          <ProjectStudyStoryPoint icon="check" text="After working closely with the Facetune team to redesign the Facetune app with the Lightricks Design System, the team is not only working more efficiently but receiving positive feedback from users and seeing an uptick in engagement and subscription rates." />
        </>}
        images="noImages"
      />}
    />
  )
}

export default LighthouseResults
