import * as React from "react"
import Seo from "../../components/seo"
import "../../styles/styles.sass"
import Header from "../../components/header"
import Footer from "../../components/footer"
import LighthouseDetails from "../../views/lighthouse/lighthouseDetails"
import LighthouseSummary from "../../views/lighthouse/lighthouseSummary"
import LighthouseSituation from "../../views/lighthouse/lighthouseSituation"
import LighthouseApproach from "../../views/lighthouse/lighthouseApproach"
import LighthouseOutcome from "../../views/lighthouse/lighthouseOutcome"
import HireBanner from "../../views/hire/hireBanner"

const LighthousePage = () => {
  return (
  <main className="mercuryView projectView projectLighthouse">
    <Seo
      title="Phil Amour — Lightricks Design System"
      description="Read my case study on Lightricks Design System to learn about my role and approach to product design."
      image="/imageSEOLightricks.jpg"
    />
    
    <Header headerType="detail" />

    <article className="mercuryContent">
      <LighthouseDetails />

      <LighthouseSummary />      

      <LighthouseSituation />

      <LighthouseApproach />

      <LighthouseOutcome />

      <section className="mercurySection contactSection">
        <HireBanner
          bannerTitle="Like the work I've done with Lightricks?"
          bannerSubtitle=""
        />
      </section>
    </article>
    
    <Footer />
  </main>
  )
}

export default LighthousePage
