import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"

const LighthouseMobileApp = () => {
  return (
  <ProjectStudyFeature
    id="mobileApp"
    title="Mobile App"
    subtitle="In addition to the design system in Figma and a repository of Origami Prototypes, I have designed and worked with developers to develop an internal iOS and Android app that would showcase all the implemented Design System components with all the properties exposed and adjustable, as well as serve as a portable guide for the design language styles. Anyone at the company could download the app to see, tweak and interact with all of the implemented components, switch between color kits and schemes, as well as test out cross-product experiments."
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="plainCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/lighthouse/mobileAppiOS.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="An mobile design system app."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="plainCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/lighthouse/mobileAppAndroid.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="An mobile design system app."/>}
      />
    </>}
  />
  )
}

export default LighthouseMobileApp
