import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"

const LighthouseDesignLanguage = () => {
  return (
  <ProjectStudyFeature
    id="designLanguage"
    title="Design Language"
    subtitle={<>
      The design language library was intended to bring a consistent style across all products and platforms and included static colors, semantic colors, font styles, shadows, blur, corner radii, gradients, and grid system.
      <br /><br />
      My contribution was a color framework which is appearance-agnostic, scalable, flexible, understandable to both designers and developers, and supports theming. We've made it easy for each product team to specify their app-specific accent colors in the so called Color Kit which would then impact how all design system components looked in their product.
    </>}
    content={<>
      <ProjectStudyStory
        previewPosition="cover"
        cardStyle="primaryCell"
        title=""
        subtitle=""
        images="imageOnly"
        image1={<StaticImage
          src="../../images/lighthouse/colors.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A design language colors mockup."/>}
      />

    </>}
  />
  )
}

export default LighthouseDesignLanguage
