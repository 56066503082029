import * as React from "react"
import ProjectStudyEpic from "../project/projectStudyEpic"
import ProjectStudyStory from "../project/projectStudyStory"
import { StaticImage } from "gatsby-plugin-image"

const LighthouseSituation = () => {
  return (
  <ProjectStudyEpic
    title="Situation"
    subtitle=""
    content={
    <div className="epicRow">
      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          title="Audience"
          subtitle="Due to the fact that the audience of this project was twofold and included both Lightricks employees (most notably Product Designers and Developers), as well as the end-users of Lightricks apps, our main goal was to help deliver great experiences to our customers across multiple products and platforms while creating a coherent and consistent system that was thought-through and intuitive for both Designers and Developers."
          images="noImages"
        />
        
        <ProjectStudyStory
          previewPosition="cover"
          cardStyle="primaryCell"
          title=""
          subtitle=""
          images="imageOnly"
          image1={<StaticImage
            src="../../images/lighthouse/audience.jpg"
            placeholder="blurred"
            layout="constrained"
            quality={95}
            alt="A startup employee working on a computer."/>}
        />
      </div>

      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          title="The Problem"
          subtitle={<>
            Refining existing product features or designing new ones was inefficient and chaotic, leading to subpar inconsistent experiences and unnecessary frustration and misunderstanding between designers and developers. 
            <br /><br />
            Beginning with the design process - when a designer started working on improvements to an existing feature, they would - in most of the cases - have to take screenshots of an app in its current state, or hunt for a design file of that specific feature with UI elements being standalone and in many cases not a source of truth to what was implemented in the product. Similarly, when starting to work on a new feature, the designer had to gather existing app UI elements, and find relevant colors and font styles to use in their new designs. 
            <br /><br />
            Once a UI flow was designed, refined, and approved, the Designer would begin annotation of the UI to not only to provide the Developers with the logic and behavior, but to create annotated dimensional guides for sizes and spacing of all the UI elements on each screen, as well as add all the descriptions of fonts and colors used - specified in fixed hex color values, font weights and sizes, resulting in an incredibly inefficient handover. In many cases, most of the UI would be implemented from scratch even if similar UI was already in place in the app or used across other apps in the organization. 
            <br /><br />
            During this process, there would be a lack of understanding between Designers and Developers due to lack of common language and processes across teams leading to frustration and endless back and forth. Some features were built once and then replicated across multiple apps without having a single source of truth. If any improvements or fixes were made in once app, other teams would not benefit from the changes. This led to scaling challenges, inefficiencies, and a lot of time spent on additional training when designers and developers would move between different teams across the organization to find out that different processes were in place. Cross-platform inconsistencies were the norm. 
            <br /><br />
            In a nutshell, the most common problems were lack of reusability of common UI elements, colors, and fonts; no centralized knowledge base and nonexistent single source of truth.
          </>}
          images="noImages"
        />
      </div>
    </div>
    }
  />
  )
}

export default LighthouseSituation
