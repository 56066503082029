import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"

const LighthouseInstructions = () => {
  return (
  <ProjectStudyFeature
    id="instructions"
    title="Instructions"
    subtitle="To help designers get started, I have created a set of instructions on how to use the design system."
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/lighthouse/instructions1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="An instructions mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/lighthouse/instructions2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="An instructions mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/lighthouse/instructions3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="An instructions mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/lighthouse/instructions4.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="An instructions mockup."/>}
      />
    </>}
  />
  )
}

export default LighthouseInstructions
